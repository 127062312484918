/**
 * Page Team
 * Render team of contributor to blog post view
 */
import React from "react"

import Layout from "../components/layout"
import Jumbotron from "../components/Jumbotron"
import SEO from "../components/seo"
import authors from '../util/authors'
import { Card, CardBody, CardTitle, Row, Container, Col } from 'reactstrap'
import AntiKangenImage from '../images/umbu-rambu.jpg'
import { slugify } from '../util/utilityFunctions'

const TeamPage = () => (
  <Layout pageTitle="Our Team">
    <SEO 
      title="Team" 
      lang="id"
      img="https://firebasestorage.googleapis.com/v0/b/waingapu-open-mind.appspot.com/o/thumbnail%2Fpeople-grow-potted-plants-2x.jpg?alt=media&token=1e576fad-1861-4eee-a318-c49508152806"
      description="Team Waingapu Open Mind"      
    />
    <Jumbotron title="Team Waingapu Open Mind" info="Kumpulan anak-anak waingapu yang berkontribusi pada artikel yang ada di Waingapu Open Mind. Mereka adalah anak-anak yang berani untuk menuangkan argumen mereka di depan publik mengenai isu-isu peristiwa yang sudah, sedang dan akan terjadi di dunia ini." />
    <Container>
      <Row>
        { authors.map(author => (
          <Col md="4">
            <Card className="text-center">
              <img src={AntiKangenImage} style={{ maxWidth: '100%'}}  alt={author.name} /> 
              <CardBody>
                <CardTitle className=""><h1>{author.name}</h1></CardTitle>
                <CardTitle>{author.bio}</CardTitle>
                <Container className="social-share-links">
                  <ul>
                    <li>
                      {
                       author.facebook === undefined ? '' : <a href={author.facebook} className="facebook" target="_blank" rel="noopener noreferrer"> <i className="fab fa-facebook-f fa-2x" /></a>
                      }
                    </li>
                    <li>
                      {
                        author.twitter === undefined ? '' : <a href={author.twitter} className="twitter" target="_blank" rel="noopener noreferrer"> <i className="fab fa-twitter fa-2x" /> </a>
                      }
                    </li>
                    <li>
                      {
                        author.google === undefined ? '' : <a href={author.google} className="google" target="_blank" rel="noopener noreferrer"> <i className="fab fa-google fa-2x" /> </a>
                      }
                    </li>
                    <li>
                      {
                        author.instagram === undefined ? '' : <a href={author.instagram} className="instagram" target="_blank" rel="noopener noreferrer"> <i className="fab fa-instagram fa-2x" /> </a>
                      }
                    </li>
                  </ul>
                </Container>
                <a className="text-uppercase btn btn-outline-dark"  color="primary" href={`/author/${slugify(author.name)}`}>View posts</a>
              </CardBody>
            </Card>
         </Col>
        ))}
      </Row>
    </Container>
    <br/>
    <br/>
  </Layout>
)

export default TeamPage

//             <img src={AntiKangenImage} style={{ maxWidth: '100%'}} alt="John profile" /> 
//           <Col md="8">
//             <Card>
//               <CardBody>
//                 <CardTitle>{authors[2].name}</CardTitle>
//                 <CardTitle>{authors[2].bio}</CardTitle>
//                 className="text-uppercase" color="primary" href={`/author/${slugify(authors[2].name)}`}>View posts</Button>
//               </CardBody>
//             </Card>
//           </Col>
// // 
// File ini digunakan untuk menyimpan data author, dimana author ini adalah kontributor dalam blog ini.
const authors = [
  {
    name: 'Umbu Rambu',
    imageUrl: 'umbu-rambu.jpg',
    bio: 'Founder | Admin | Redaksi | Editor | Pencari Kebenaran | Menuju Tak Terbatas Dan Melampauinya.',
    facebook: 'https://www.facebook.com/umbu.rambu.581',
  },
]

module.exports = authors
